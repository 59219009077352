<template>
    <div>
        <van-nav-bar title="会议议程" left-arrow @click-left="onClickLeft" />
        <div class="welcome-div">
            “2024年陕西省职业教育活动周启动仪式”议程：
        </div>
        <van-steps direction="vertical" :active="17">
            <van-step>
                <p>1.学生创新创业优秀项目路演展示</p>
            </van-step>
            <van-step>
                <p>2.陕西省职业教育改革发展专题宣传片（秦韵匠心 育才兴陕）</p>
            </van-step>
            <van-step>
                <p>3.开场舞：</p>
                <p>《踏歌》西安音乐学院附属中等音乐学校</p>
                <p>《九州同》西安陕文投睿职业高级中学</p>
            </van-step>
            <van-step>
                <p>4.西安市教育局领导致辞</p>
            </van-step>
            <van-step>
                <p>5.民乐表演：《陕北民歌联奏》西安音乐学院附属中等音乐学校</p>
            </van-step>
            <van-step>
                <p>6.职业院校学生代表发言</p>
            </van-step>
            <van-step>
                <p>7.工匠大师代表发言</p>
            </van-step>
            <van-step>
                <p>8.新疆舞蹈：《石榴红了》陕西艺术职业学院</p>
            </van-step>
            <van-step>
                <p>9.职业院校教师代表发言</p>
            </van-step>
            <van-step>
                <p>10.行业企业代表发言</p>
            </van-step>
            <van-step>
                <p>11.武术舞蹈：《咏春》西安陕文投睿职业高级中学</p>
            </van-step>
            <van-step>
                <p>12.2022年国家级教学成果获奖项目领奖</p>
            </van-step>
            <van-step>
                <p>13.朗诵：《新时代职教学子青春宣言》西安铁路职业技术学院</p>
            </van-step>
            <van-step>
                <p>14.陕西省教育厅领导讲话</p>
            </van-step>
            <van-step>
                <p>15.宣布2024年陕西省职业教育活动周启动</p>
            </van-step>
            <van-step>
                <p>16.歌伴舞：《奋进新时代》陕西府谷职业中等专业学校</p>
            </van-step>
            <van-step>
                <p>17.启动仪式闭幕</p>
            </van-step>
        </van-steps>

    </div>
</template>

<script>

export default {
    methods: {
        onClickLeft() {
            this.$router.push('/')
        }
    }
};
</script>

<style scoped>
.welcome-div {
    text-indent: 2em;
    line-height: 30px;
    margin: 10px;
}

.mark {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
</style>
